import React, { useEffect, useContext } from "react";
import { ProfileContext } from "../../../context/ContextProfile";
import AldealistaLogo from "../../../assets/images/aldealista-logo.png";
import RuralBox from "../../../assets/img/ruralbox.png";
import Houses from "./Houses";
import Jobs from "./Jobs";

function Grid({ images, isJob, isExperience, isHousing }) {
  const { village, modifyVillage, modifyImages, modifyImage } =
    useContext(ProfileContext);

  const onImageClick = (image) => {
    var filename = "";

    if (image.isVideo == 1) {
      filename = youtube_parser(image.path[0]);
    } else {
      filename = removeFileExtension(image.path[0]);
    }
    modifyImages(image);

    modifyImage(image);

    window.history.pushState({}, "", `/images/${filename}`);
  };

  function youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  function removeFileExtension(filename) {
    // Find the last dot in the filename
    const lastDotIndex = filename.lastIndexOf(".");

    // If there's no dot, return the original filename
    if (lastDotIndex === -1) return filename;

    // Return the filename without the extension
    return filename.substring(0, lastDotIndex);
  }

  return (
    <>
      {isHousing && village.code === "graus" && <Houses />}
      {isJob && village.code === "graus" && <Jobs />}
      {isExperience && (
        <div
          className="row fix-row-container"
          style={{
            margin: 0,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            marginLeft: "5px",
          }}
        >
          <img
            src={RuralBox}
            alt="RuralBox Logo"
            width={150}
            style={{ height: "auto", width: "450px", marginTop: "10px" }}
          />
          <p
            style={{ marginTop: "0px", fontSize: "22px", textAlign: "center" }}
          >
            Próximamente
          </p>
        </div>
      )}

      {((isJob && village.code !== "graus") ||
        (isHousing && village.code !== "graus")) && (
        <div
          className="row fix-row-container"
          style={{
            margin: 0,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            marginLeft: "5px",
          }}
        >
          <img
            src={AldealistaLogo}
            alt="Aldealista Logo"
            width={150}
            style={{ height: "auto", width: "200px", marginTop: "40px" }}
          />
          <p
            style={{ marginTop: "20px", fontSize: "22px", textAlign: "center" }}
          >
            Próximamente
          </p>
        </div>
      )}

      {images && !isJob && !isExperience && !isHousing && (
        <div
          className="row fix-row-container"
          style={{
            margin: 0,
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            marginLeft: "5px",
          }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className="col-4"
              style={{
                padding: 0,
                border: "4px solid #fff",
                maxWidth: "212px",
              }}
            >
              {" "}
              {/* Adjust col size based on your needs */}
              <div className="image-container">
                <img
                  onClick={() => {
                    onImageClick(image);
                  }}
                  key={image.id}
                  src={
                    image.isVideo == 0
                      ? `https://aldealista.com/p/${image.path[0]}?w=248&fit=crop&auto=format`
                      : `https://img.youtube.com/vi/${youtube_parser(
                          image.path[0]
                        )}/0.jpg`
                  }
                  srcSet={
                    image.isVideo == 0
                      ? `https://aldealista.com/p/${image.path[0]}?w=248&fit=crop&auto=format&dpr=2 2x`
                      : `https://img.youtube.com/vi/${youtube_parser(
                          image.path[0]
                        )}/0.jpg`
                  }
                  alt={image.title}
                  loading="lazy"
                  className="img-fluid imageResize-qr" // 'img-fluid' for responsive images
                />
                <div className="image-title-bar">
                  <span className="image-title-bar-text">{image.title}</span>
                  {image.isVideo == 0 ? (
                    image.path.length > 1 && (
                      <span>
                        <i
                          className="fa fa-copy"
                          aria-label={`info about ${image.title}`}
                        ></i>{" "}
                        {/* File Copy Icon */}
                      </span>
                    )
                  ) : (
                    <span>
                      <i
                        className="fa fa-youtube-play"
                        aria-label={`info about ${image.title}`}
                      ></i>{" "}
                      {/* YouTube Icon */}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default Grid;
