import React, { useState, useEffect, useContext } from "react";
import { Tab, Tabs } from "react-bootstrap";

import EmptyImg from "../../../assets/img/emptyVillage.png";
import Grid from "./Grid";
import SlidersTop from "./SlidersTop";
//import {Grid as GridMuiu} from "@mui/material/Grid";
import { ProfileContext } from "../../../context/ContextProfile";
import { TabContext } from "../../../context/Context";

import { IonIcon } from "@ionic/react";
import {
  diamond,
  diamondOutline,
  images,
  imagesOutline,
  navigateCircle,
  navigateCircleOutline,
  bed,
  bedOutline,
  briefcase,
  briefcaseOutline,
  cubeOutline,
  cube,
} from "ionicons/icons";

function GridGallery() {
  const { tabIndex, modifyTabIndex } = useContext(TabContext);
  const { village, modifyVillage, villageData } = useContext(ProfileContext);
  const [key, setKey] = useState("Top");

  useEffect(() => {
    console.log("Village grid data", village);
  }, [village]);

  return (
    <>
      {village.villagePictures && (
        <>
          {village.villagePictures.mainGallery.length === 0 ? (
            <>
              <p>Error loading</p>
            </>
          ) : (
            <>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 custom-tab-list "
                style={{
                  marginBottom: "0px !important",
                }}
              >
                <Tab
                  eventKey="Top"
                  title={
                    <>
                      <IonIcon
                        icon={key === "Top" ? diamond : diamondOutline}
                        className={
                          key === "Top" ? "active-tab" : "inactive-icon"
                        }
                      />
                      <p
                        className={
                          key === "Top" ? "active-tab" : "inactive-icon"
                        }
                      >
                        Top
                      </p>
                    </>
                  }
                >
                  {/* Your custom Grid component for Main Gallery */}
                  <SlidersTop
                    mostVisited={village.villageMostVisited}
                    recommendations={village.villageRandomPosts}
                    displayName={village.villageData.displayName}
                    code={village.code}
                    profilePicture={village.villageData.profilePicture}
                    village={village}
                  />
                </Tab>
                <Tab
                  eventKey="mainGallery"
                  title={
                    <>
                      <IonIcon
                        icon={key === "mainGallery" ? images : imagesOutline}
                        className={
                          key === "mainGallery" ? "active-tab" : "inactive-icon"
                        }
                      />
                      <p
                        className={
                          key === "mainGallery" ? "active-tab" : "inactive-icon"
                        }
                      >
                        Perfil
                      </p>
                    </>
                  }
                >
                  {/* Your custom Grid component for Main Gallery */}
                  <Grid
                    images={village.villagePictures.mainGallery}
                    displayName={village.villageData.displayName}
                    code={village.code}
                    profilePicture={village.villageData.profilePicture}
                    village={village}
                  />
                </Tab>
                <Tab
                  eventKey="experiencias"
                  title={
                    <>
                      <IonIcon
                        icon={key === "experiencias" ? cube : cubeOutline}
                        className={
                          key === "experiencias"
                            ? "active-tab"
                            : "inactive-icon"
                        }
                      />
                      <p
                        className={
                          key === "experiencias"
                            ? "active-tab"
                            : "inactive-icon"
                        }
                      >
                        Rural Box
                      </p>
                    </>
                  }
                >
                  {/* Your custom Grid component for Main Gallery */}
                  <Grid
                    images={village.villagePictures.mainGallery}
                    displayName={village.villageData.displayName}
                    code={village.code}
                    profilePicture={village.villageData.profilePicture}
                    village={village}
                    isExperience={true}
                  />
                </Tab>
                <Tab
                  eventKey="casas"
                  title={
                    <>
                      <IonIcon
                        icon={key === "casas" ? bed : bedOutline}
                        className={
                          key === "casas" ? "active-tab" : "inactive-icon"
                        }
                      />
                      <p
                        className={
                          key === "casas" ? "active-tab" : "inactive-icon"
                        }
                      >
                        Casas
                      </p>
                    </>
                  }
                >
                  {/* Your custom Grid component for Main Gallery */}
                  <Grid
                    images={village.villagePictures.mainGallery}
                    displayName={village.villageData.displayName}
                    code={village.code}
                    profilePicture={village.villageData.profilePicture}
                    village={village}
                    isHousing={true}
                  />
                </Tab>
                <Tab
                  eventKey="Empleo"
                  title={
                    <>
                      <IonIcon
                        icon={key === "Empleo" ? briefcase : briefcaseOutline}
                        className={
                          key === "Empleo" ? "active-tab" : "inactive-icon"
                        }
                      />
                      <p
                        className={
                          key === "Empleo" ? "active-tab" : "inactive-icon"
                        }
                      >
                        Empleo
                      </p>
                    </>
                  }
                >
                  <Grid
                    images={village.villagePictures.mainGallery}
                    displayName={village.villageData.displayName}
                    code={village.code}
                    profilePicture={village.villageData.profilePicture}
                    village={village}
                    isJob={true}
                  />
                </Tab>
              </Tabs>

              <div className="top-line"></div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default GridGallery;
